export enum ServiceTypeEnum {
  CIT_ID = "CIT_ID", //Documento
  CIT_PLATE = "CIT_PLATE", //Vehiculo
  SOL = "SOL", //Solicitud
  CDP = "CDP", //Convenios
}

export enum ANTServiceTypeEnum {
  CIT_ID = "CIT",
  CIT_PLATE = "CIT",
  SOL = "SOL",
  CDP = "CDP",
}

export enum PaymentRegisterNameEnum {
  ID_NUMBER = "idNumber",
  VEHICLE_PLATE = "vehiclePlate",
  ORDER_NUMBER = "orderNumber",
}
