/* eslint-disable import/no-cycle */
import { combineEpics } from "redux-observable";
import { consultPaymentEpic } from "./consultPaymentEpic/consult_payment_epic";
import {
  setTransactionDataEpic,
  subscribeToTransactionEpic,
} from "../../components/Checkout/store/epics";
import { EpicCustom } from "../store.interfaces";

export default combineEpics(
  //@ts-ignore
  subscribeToTransactionEpic,
  setTransactionDataEpic,
  consultPaymentEpic
) as unknown as EpicCustom;
