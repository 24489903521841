/* eslint-disable import/no-cycle */
import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import appReducer from "./reducers/app/app";
import appEpic from "./epics/epics";
import checkoutReducer from "../components/Checkout/store/reducer/reducer";

export const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    app: appReducer,
    checkout: checkoutReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(epicMiddleware),
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
});

epicMiddleware.run((action$, state$) =>
  appEpic({ action$, state$, dispatch: store.dispatch })
);
