import { IDocumentsToPayWebsocketResponseSuccess } from "../../../../store/epics/consultPaymentEpic/consult_payment_epic.interfaces";
import { IQueryServiceResponseANT } from "../../../../store/interfaces/AppState.interfaces";

export const convertToResponseANT = (
  responseWebsocket: IDocumentsToPayWebsocketResponseSuccess
): IQueryServiceResponseANT => {
  const responseANT: IQueryServiceResponseANT = {
    amount: Number(responseWebsocket.count),
    codError: responseWebsocket.errorCode,
    identification: responseWebsocket.documentNumber,
    message: responseWebsocket.message,
    name: responseWebsocket.name,
    requestName: responseWebsocket.requestName,
    typeIdentification: responseWebsocket.documentType,
    value: Number(responseWebsocket.amount),
  };

  return responseANT;
};
