import { createAction, Dispatch } from "@reduxjs/toolkit";
import get from "lodash/get";
import {
  catchError,
  EMPTY,
  forkJoin,
  ignoreElements,
  mergeMap,
  Observable,
  tap,
} from "rxjs";
import { filter } from "rxjs/operators";
import {
  setAvailability,
  setCommission,
  setCurrentContainer,
  setQueryServiceRequestANT,
  setQueryServiceResponseANT,
  setQueryServiceResponseErrorANT,
} from "../../actions/actions";
import axios from "../../../shared/utils/axios";
import {
  IAvailabilityResponse,
  ICommissionRequest,
  ICommissionResponse,
  IQueryServiceResponseANT,
  IQueryServiceResponseErrorANT,
} from "../../interfaces/AppState.interfaces";
import { createWebsocket } from "../../../shared/utils";
import { ServicesForm } from "../../../components/Home/PaymentServices/PaymentServices.interfaces";
// eslint-disable-next-line import/no-cycle
import { EpicCustom } from "../../store.interfaces";
import { PaymentContainerEnum } from "../../../components/Home/PaymentServices/enums/payment_containers";
import { convertToWebsocketRequest } from "../../../shared/utils/queryPayment/convertToWebsocketRequest/convert_to_websocket_request";
import {
  API_ROUTES,
  PUBLIC_COMMISSION_URL,
  PUBLIC_MERCHANT_ID,
  WS_ANT_DOCUMENTS_URL,
} from "../../../shared/constants";
import {
  IDocumentsToPayWebsocketResponseError,
  IDocumentsToPayWebsocketResponseSuccess,
  IDocumentsToPayWebsocketResponseType,
} from "./consult_payment_epic.interfaces";
import { convertToResponseANT } from "../../../shared/utils/queryPayment/convertToResponseANT/convert_to_response_ANT";
import { convertToResponseErrorANT } from "../../../shared/utils/queryPayment/convertToResponseErrorANT/convert_to_response_error_ANT";
import { ANTTitleEnum } from "../../../shared/enums/errorEnum";
import { convertMessage } from "../../../shared/utils/availabilityMessage/availabilityMessage";

const isErrorResponseWS = (responseWebsocket: any): boolean =>
  responseWebsocket.statusCode || responseWebsocket.code;

const isValidData = (responseWebsocket: any): boolean => {
  const validKeys = ["amount", "documentType", "name"];
  const isValid = Object.keys(responseWebsocket).some((key) =>
    validKeys.some((item) => item === key)
  );
  return isValid;
};

const websocketsObservable = (request: ServicesForm) => {
  const observableWS = new Observable<IQueryServiceResponseANT>(
    (subscriber) => {
      const socket = createWebsocket(WS_ANT_DOCUMENTS_URL ?? "");

      socket.addEventListener("open", () => {
        const requestDocumentsToPay = convertToWebsocketRequest(request);
        socket.send(JSON.stringify(requestDocumentsToPay));
      });

      socket.addEventListener("message", (event: MessageEvent<string>) => {
        const responseWebsocket: IDocumentsToPayWebsocketResponseType = get(
          JSON.parse(event.data),
          "payload"
        );

        if (isErrorResponseWS(responseWebsocket)) {
          subscriber.error(
            convertToResponseErrorANT(
              responseWebsocket as IDocumentsToPayWebsocketResponseError
            )
          );
          socket.close();
          return;
        }

        if (isValidData(responseWebsocket)) {
          const responseANT = convertToResponseANT(
            responseWebsocket as IDocumentsToPayWebsocketResponseSuccess
          );

          const requestDocumentsToPay = convertToWebsocketRequest(request);
          const { serviceType } = requestDocumentsToPay.request;
          const responseANTmodified: IQueryServiceResponseANT = {
            ...responseANT,
            procedureName: !responseANT.procedureName
              ? serviceType
              : responseANT.procedureName,
          };

          subscriber.next(responseANTmodified);
          subscriber.complete();
          socket.close();
        }
      });

      socket.addEventListener("error", (error) => {
        subscriber.error(error);
        socket.close();
      });
    }
  );

  return observableWS;
};

const getCommission = (
  request: { payload: ServicesForm; type: string },
  dispatch: Dispatch
) =>
  websocketsObservable(request.payload).pipe(
    catchError((e) => {
      dispatch(setQueryServiceResponseErrorANT(e));
      throw new Error();
    }),
    tap((response) => {
      dispatch(setQueryServiceResponseANT(response));
    }),
    mergeMap((response) => {
      if (response.value === 0) {
        dispatch(setCurrentContainer(PaymentContainerEnum.ERROR));
        return EMPTY;
      }

      const commissionBody: ICommissionRequest = {
        currency: "USD",
        totalAmount: Number(response.value),
      };

      return axios
        .post<ICommissionResponse>(
          PUBLIC_COMMISSION_URL ?? "",
          commissionBody,
          {
            headers: {
              "Public-Merchant-Id": PUBLIC_MERCHANT_ID ?? "",
            },
          }
        )
        .pipe(
          catchError(() => {
            dispatch(setQueryServiceRequestANT());
            throw new Error();
          }),
          tap(({ data }) => dispatch(setCommission(data)))
        );
    })
  );

const getAvailability = (dispatch: Dispatch) =>
  axios.get<IAvailabilityResponse>(API_ROUTES.availability).pipe(
    catchError(() => {
      dispatch(
        setQueryServiceResponseErrorANT({
          title: ANTTitleEnum.OCURRIO_UN_ERROR,
        } as IQueryServiceResponseErrorANT)
      );
      throw Error();
    }),
    tap(({ data }) => {
      data.message = convertMessage(data.message);
      dispatch(setAvailability(data));
    })
  );

export const consultPayment = createAction<ServicesForm>("CONSULT_PAYMENT");

export const consultPaymentEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(consultPayment.match),
    tap(({ payload }) => {
      dispatch(setCurrentContainer(PaymentContainerEnum.LOADING));
      dispatch(setQueryServiceRequestANT(payload));
    }),
    mergeMap((request) =>
      forkJoin([
        getAvailability(dispatch),
        getCommission(request, dispatch),
      ]).pipe(
        catchError(() => {
          dispatch(setCurrentContainer(PaymentContainerEnum.ERROR));
          return EMPTY;
        }),
        tap(() => {
          dispatch(setCurrentContainer(PaymentContainerEnum.SUMMARY));
        })
      )
    ),
    ignoreElements()
  );
