import { AppProps } from "next/app";
import "../public/css/styles.css";
import { FC, useEffect } from "react";
import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import DefaultLayout from "../src/layouts/Default/Default";
import { createEmotionCache } from "../server/utils/create_emotion_cache";
import theme from "../src/themes/theme";
import { HEAD_LINKS } from "../src/shared/constants";
import { store } from "../src/store/store";
import { KushkiJsProvider } from "../src/context/useKushkiJSContext/useKushkiJsContext";

const clientCache = createEmotionCache();

const MyApp: FC<AppProps & { emotionCache: EmotionCache }> = ({
  Component,
  pageProps,
  emotionCache = clientCache,
}) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM ?? "" });
    const jssStyles = document.querySelector("#jss-server-side");

    if (jssStyles) jssStyles.parentElement?.removeChild(jssStyles);
  }, []);

  const merchantId = process.env.NEXT_PUBLIC_MERCHANT_ID;
  const isTestEnvironment = process.env.NEXT_PUBLIC_ENV_NAME !== "primary";

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>ANT</title>
        <meta name="theme-color" content="#005EA6" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="description" content="Página de pagos ANT" />

        {HEAD_LINKS.map((linkProps, index) => (
          //@ts-ignore
          <link key={index} {...linkProps} />
        ))}
      </Head>

      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <KushkiJsProvider
            merchantId={merchantId ?? ""}
            inTestEnvironment={isTestEnvironment}
          >
            <DefaultLayout>
              <Component {...pageProps} />
            </DefaultLayout>
          </KushkiJsProvider>
        </ThemeProvider>
      </Provider>
    </CacheProvider>
  );
};

export default MyApp;
