/* eslint-disable max-len */
/* eslint-disable no-useless-concat */
import { createTheme } from "@mui/material/styles";

const Palette = {
  primary: {
    dark2: "#004376",
    dark: "#005697",
    main: "#005EA6",
    light: "#5493C3",
    light2: "#B0CDE3",
    light3: "#E6EFF6",
    components: {
      light: {
        default: "#4499EE",
        hover: "#3879CD",
        focus: "#3F8BE0",
        disable: "#BFDFFA",
      },
    },
  },
  secondary: {
    dark2: "#B29204",
    dark: "#E4BB05",
    main: "#FBCD06",
    light: "#FCDE58",
    light2: "#FEF0B2",
    light3: "#FFFAE6",
  },
  error: {
    dark2: "#8F2A29",
    dark: "#B73635",
    main: "#C93B3A",
    light: "#DB7C7B",
    light2: "#EEC2C2",
    light3: "#FAEBEB",
  },
  success: {
    dark2: "#007867",
    dark: "#009A84",
    main: "#00A991",
    light: "#54C5B5",
    light2: "#B0E4DD",
    light3: "#E6F6F4",
  },
  warning: {
    dark2: "#CF711A",
    dark: "#DFA720",
    main: "#FBC748",
    light: "#FFD879",
    light2: "#FEE19B",
    light3: "#FFF1D0",
  },
  background: {
    paper: "#FEFEFF",
    default: "#FAFCFE",
    paper3: "#F0F4FB",
  },
  neutral: {
    900: "#2F3032",
    800: "#3E3F41",
    700: "#505254",
    600: "#717376",
    500: "#8D8F91",
    400: "#A0A1A3",
    300: "#BEBFC0",
    200: "#D3D4D5",
    100: "#F1F1F1",
    50: "#FFFFFF",
    components: {
      shadow: "#525967",
      disabled: "#B8BFCC",
    },
  },
  text: {
    primary: "#005EA6",
    secondary: "#545C62",
    error: "#C93B3A",
    dark: "#001C32",
    blue: "#005EA6",
    grey: "#545C62",
    lightGrey: "#B0B4B6",
    white: "#FEFEFF",
  },
  gradients: {
    gradientA: "linear-gradient(180deg, #00E6B2 0%, #023365 100%)",
    gradientB: "linear-gradient(180deg, #0093A2 0%, #00E6B2 100%)",
    gradientC: "linear-gradient(135deg, #023365 0%, #1E65AE 100%)",
    gradientD: "linear-gradient(180deg, #023365 100% , #00E6B2 0%)",
    gradientE: "linear-gradient(180deg, #FFFFFF 0%, #F7FAFC 100%)",
  },
};

const ThemeFonts = {
  screenxl: {
    h1: 64,
    h2: 56,
    h3: 48,
    h4: 40,
    h5: 32,
    h6: 24,
    subtitle1: 16,
    subtitle2: 14,
    body1: 16,
    body2: 14,
    caption: 14,
    overline: 12,
    numericText: 15,
    button: 14,
    buttonsm: 12,
    buttonlg: 16,
    inputLabel: 12,
    inputText: 16,
    helperText: 14,
    tooltip: 11,
    avatarLetter: 16,
    chip: 14,
    tag: 12,
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 360,
    md: 960,
    lg: 1280,
    xl: 1600,
  },
};

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      //@ts-ignore
      dark2: Palette.primary.dark2,
      dark: Palette.primary.dark,
      main: Palette.primary.main,
      light: Palette.primary.light,
      light2: Palette.primary.light2,
      light3: Palette.primary.light3,
      components: {
        light: {
          default: Palette.primary.components.light.default,
          hover: Palette.primary.components.light.hover,
          //@ts-ignore
          focus: Palette.primary.components.light.focus,
          disable: Palette.primary.components.light.disable,
        },
      },
    },
    secondary: {
      //@ts-ignore
      dark2: Palette.secondary.dark2,
      dark: Palette.secondary.dark,
      main: Palette.secondary.main,
      light: Palette.secondary.light,
      light2: Palette.secondary.light2,
      light3: Palette.secondary.light3,
    },
    error: {
      //@ts-ignore
      dark2: Palette.error.dark2,
      dark: Palette.error.dark,
      main: Palette.error.main,
      light: Palette.error.light,
      light2: Palette.error.light2,
      light3: Palette.error.light3,
    },
    success: {
      //@ts-ignore
      dark2: Palette.success.dark2,
      dark: Palette.success.dark,
      main: Palette.success.main,
      light: Palette.success.light,
      light2: Palette.success.light2,
      light3: Palette.success.light3,
    },
    warning: {
      //@ts-ignore
      dark2: Palette.warning.dark2,
      main: Palette.warning.main,
      dark: Palette.warning.dark,
      light: Palette.warning.light,
      light2: Palette.warning.light2,
      light3: Palette.warning.light3,
    },
    background: {
      paper: Palette.background.paper,
      default: Palette.background.default,
      //@ts-ignore
      paper3: Palette.background.paper3,
    },
    neutral: {
      //@ts-ignore
      grey9: Palette.neutral[900],
      grey8: Palette.neutral[800],
      grey7: Palette.neutral[700],
      grey6: Palette.neutral[600],
      grey5: Palette.neutral[500],
      grey4: Palette.neutral[400],
      grey3: Palette.neutral[300],
      grey2: Palette.neutral[200],
      grey1: Palette.neutral[100],
      white: Palette.neutral[50],
      components: {
        shadow: Palette.neutral.components.shadow,
        disabled: Palette.neutral.components.disabled,
      },
    },
    text: {
      primary: Palette.text.primary,
      secondary: Palette.text.secondary,
      //@ts-ignore
      dark: Palette.text.dark,
      blue: Palette.text.blue,
      error: Palette.text.error,
      white: Palette.text.white,
      lightGrey: Palette.text.lightGrey,
      grey: Palette.text.grey,
    },
    gradients: {
      gradientA: Palette.gradients.gradientA,
      gradientB: Palette.gradients.gradientB,
      gradientC: Palette.gradients.gradientC,
      gradientD: Palette.gradients.gradientD,
      gradientE: Palette.gradients.gradientE,
    },
  },
  breakpoints,
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontSize: `${ThemeFonts.screenxl.h1}px`,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%",
      letterSpacing: "-0.015em",
    },
    h2: {
      fontSize: `${ThemeFonts.screenxl.h2}px`,
      fontWeight: 500,
      lineHeight: "120%",
      fontStyle: "normal",
      letterSpacing: "-0.015em",
    },
    h3: {
      fontSize: `${ThemeFonts.screenxl.h3}px`,
      fontWeight: 600,
      lineHeight: "120%",
      fontStyle: "normal",
    },
    h4: {
      fontSize: `${ThemeFonts.screenxl.h4}px`,
      fontWeight: 500,
      lineHeight: "120%",
      fontStyle: "normal",
      letterSpacing: "0.0025em",
    },
    h5: {
      fontSize: `${ThemeFonts.screenxl.h5}px`,
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h6: {
      fontSize: `${ThemeFonts.screenxl.h6}px`,
      fontWeight: 400,
      lineHeight: "120%",
      fontStyle: "normal",
      letterSpacing: "0.0015em",
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: `${ThemeFonts.screenxl.subtitle1}px`,
      fontWeight: 300,
      lineHeight: "120%",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontSize: `${ThemeFonts.screenxl.subtitle2}px`,
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "0.0025em",
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: `${ThemeFonts.screenxl.body1}px`,
      fontWeight: "normal",
      lineHeight: "150%",
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: `${ThemeFonts.screenxl.body2}px`,
      fontWeight: "normal",
      lineHeight: "150%",
    },
    caption: {
      fontFamily: "Roboto",
      lineHeight: "144%",
      fontSize: `${ThemeFonts.screenxl.caption}px`,
    },
    overline: {
      fontFamily: "Roboto",
      lineHeight: "120%",
      fontWeight: "normal",
      textTransform: "inherit",
      letterSpacing: "0.01em",
      fontSize: `${ThemeFonts.screenxl.overline}px`,
    },
    button: {
      fontFamily: "Roboto",
      lineHeight: "120%",
      fontWeight: "normal",
      textTransform: "none",
      fontSize: `${ThemeFonts.screenxl.overline}px`,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: "break-word",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "12px 30px",
          height: 48,
          borderRadius: 8,
          fontWeight: "400",
          letterSpacing: "0.0025em",
          lineHeight: "140%",
          fontFamily: "Roboto",
          fontSize: `${ThemeFonts.screenxl.button}px`,
        },
        /*sizeLarge: {
          height: 52,
          padding: "12px 30px",
          fontSize: ThemeFonts.screenxl.buttonlg + `px`
        },
        sizeSmall: {
          height: 36,
          padding: "8px 30px",
          borderRadius: 12,
          fontSize: ThemeFonts.screenxl.buttonsm + `px`
        },*/
        containedPrimary: {
          border: `1px solid ${Palette.primary.main}`,
          backgroundColor: Palette.primary.main,
          boxShadow:
            "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
          "&:hover": {
            color: Palette.neutral[50],
            backgroundColor: Palette.primary.dark2,
            border: `1px solid ${Palette.primary.dark2}`,
            boxShadow:
              "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
          },
          "&:focus, &:active": {
            backgroundColor: Palette.secondary.dark,
            border: `1px solid ${Palette.secondary.dark}`,
            color: Palette.neutral[50],
            boxShadow:
              "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
          },
          "&:disabled": {
            backgroundColor: Palette.primary.light,
            color: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light}`,
            opacity: 0.3,
          },
        },
        containedSecondary: {
          backgroundColor: Palette.neutral[50],
          border: `1px solid ${Palette.primary.main}`,
          color: Palette.primary.main,
          "&:hover": {
            backgroundColor: Palette.neutral[100],
            border: `1px solid ${Palette.primary.light}`,
            color: Palette.primary.light,
          },
          "&:focus, &:active": {
            backgroundColor: Palette.neutral[100],
            border: `1px solid ${Palette.neutral[100]}!important`,
            color: Palette.text.primary,
          },
          "&:disabled": {
            backgroundColor: Palette.neutral[50],
            border: `1px solid ${Palette.primary.light}!important`,
            color: Palette.primary.light,
            opacity: 0.3,
          },
        },
        textPrimary: {
          backgroundColor: Palette.neutral[50],
          border: `1px solid ${Palette.neutral[50]}`,
          color: Palette.primary.dark2,
          "&:hover": {
            backgroundColor: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light3}`,
            color: Palette.primary.dark2,
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
          },
          "&:focus, &:active": {
            backgroundColor: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light}`,
            color: Palette.primary.light,
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
          },
          "&:disabled": {
            backgroundColor: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light}`,
            color: Palette.primary.light,
            opacity: 0.3,
          },
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "error" },
          style: {
            backgroundColor: Palette.neutral[50],
            border: `1px solid ${Palette.error.light3}`,
            color: Palette.text.grey,
            "&:hover": {
              backgroundColor: Palette.neutral[50],
              border: `1px solid ${Palette.error.light}`,
              color: Palette.text.grey,
              boxShadow:
                "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
            },
            "&:focus, &:active": {
              backgroundColor: Palette.neutral[50],
              border: `1px solid ${Palette.error.light}`,
              color: Palette.text.error,
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: Palette.neutral[50],
              border: `1px solid ${Palette.error.main}`,
              color: Palette.error.main,
              opacity: "35%",
            },
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 10,
          height: 44,
          width: 44,
          borderRadius: 8,
          fontSize: `${ThemeFonts.screenxl.button}px`,

          //Autocomplete textfield
          "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator":
            {
              height: "auto",
            },
        },
        /*sizeSmall: {
          height: 36,
          width: 36,
          padding: 6,
          fontSize: ThemeFonts.screenxl.buttonsm + `px`,

          svg: {
            fontSize: "1.25rem!important"
          }
        },
        sizeLarge: {
          height: 52,
          width: 52,
          padding: 14,
          fontSize: ThemeFonts.screenxl.buttonlg + `px`,

          svg: {
            fontSize: "1.72rem!important"
          }
        },*/
        //@ts-ignore
        colorPrimary: {
          border: `1px solid ${Palette.primary.main}`,
          backgroundColor: Palette.primary.main,
          color: Palette.primary.light3,
          boxShadow:
            "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
          "&:hover": {
            color: Palette.neutral[50],
            backgroundColor: Palette.primary.dark2,
            border: `1px solid ${Palette.primary.dark2}`,
            boxShadow:
              "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
          },
          "&:focus, &:active": {
            backgroundColor: Palette.secondary.dark,
            border: `1px solid ${Palette.secondary.dark}`,
            color: Palette.neutral[50],
            boxShadow:
              "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
          },
          "&:disabled": {
            backgroundColor: Palette.primary.light,
            color: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light}`,
            opacity: 0.3,
          },
        },
        colorSecondary: {
          backgroundColor: Palette.neutral[50],
          border: `1px solid ${Palette.neutral[50]}`,
          color: Palette.primary.dark2,
          "&:hover": {
            backgroundColor: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light3}`,
            color: Palette.primary.dark2,
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
          },
          "&:focus, &:active": {
            backgroundColor: Palette.primary.light2,
            border: `1px solid ${Palette.primary.light2}`,
            color: Palette.primary.dark2,
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
          },
          "&:disabled": {
            backgroundColor: Palette.primary.light3,
            border: `1px solid ${Palette.primary.light3}`,
            color: Palette.primary.light,
            opacity: 0.3,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: "4px 5px",
          "&+.MuiFormControlLabel-label": {
            fontSize: `${ThemeFonts.screenxl.button}px` + "!important",
          },
          ".Mui-disabled+.MuiSwitch-track, .Mui-checked.Mui-disabled+.MuiSwitch-track":
            {
              backgroundColor: `${Palette.neutral[600]}!important`,
              opacity: "0.3" + "!important",
            },
        },
        switchBase: {
          color: `${Palette.neutral[50]}!important`,
        },
        thumb: {
          boxShadow: "none",
        },
        track: {
          borderRadius: 48,
          opacity: "initial" + "!important",
          backgroundColor: Palette.primary.light3,
        },
        colorPrimary: {
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: `${Palette.primary.main}!important`,
          },
        },
        colorSecondary: {
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: `${Palette.secondary.main}!important`,
          },
        },
        sizeSmall: {
          padding: 0,
          "&+.MuiFormControlLabel-label": {
            marginLeft: 5,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&+.MuiFormControlLabel-label": {
            fontSize: `${ThemeFonts.screenxl.button}px` + "!important",
          },
          "&.Mui-disabled": {
            color: `${Palette.neutral[600]}!important`,
            opacity: "0.3",
          },
        },
        colorPrimary: {
          color: Palette.primary.main,
          "&.Mui-checked": {
            color: Palette.primary.main,
          },
        },
        colorSecondary: {
          color: Palette.secondary.main,
          "&.Mui-checked": {
            color: Palette.secondary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&+.MuiFormControlLabel-label": {
            fontSize: `${ThemeFonts.screenxl.button}px!important`,
          },
          "&.Mui-disabled": {
            color: `${Palette.neutral[600]}!important`,
            opacity: "0.3",
          },
        },
        colorPrimary: {
          color: Palette.primary.light2,
          "&.Mui-checked": {
            color: Palette.primary.light2,
          },
        },
        colorSecondary: {
          color: Palette.secondary.dark,
          "&.Mui-checked": {
            color: Palette.secondary.dark,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px;",
          fontSize: `${ThemeFonts.screenxl.inputText}px`,
          color: Palette.text.white,
          padding: "8px 16px",
          fontWeight: "normal",
          lineHeight: "144%",
          alignItems: "center",
          boxShadow: "none",
          ".MuiAlert-message": {
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            a: {
              fontSize: 13,
              letterSpacing: "0.01em",
              margin: "0px 16px",
              color: "currentColor",
            },
          },
          ".MuiAlert-action": {
            padding: 0,
            marginRight: 0,
            alignItems: "center",
            svg: {
              fontSize: "0.9em",
              stroke: "currentColor",
            },
          },
        },
        filledSuccess: {
          backgroundColor: Palette.primary.main,
          color: Palette.primary.light3,
        },
        filledError: {
          color: Palette.text.error,
          backgroundColor: Palette.error.light3,
        },
        /*filledWarning: {
          backgroundColor: Palette.warning.light2
        },
        filledInfo: {
          backgroundColor: Palette.neutral[800]
        }*/
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: "4px 16px",
          borderRadius: "4px" + "!important",
          textTransform: "uppercase",
          fontWeight: "500",
          lineHeight: "170%",
          letterSpacing: "0.02em",
          "&.sizeLarge": {
            height: 28,
          },
          ".MuiChip-deleteIcon": {
            fontSize: "0.85em",
            stroke: "currentColor",
            color: Palette.text.primary,
            paddingLeft: "10px",
            marginRight: "0px",
          },
          ".MuiChip-label": {
            padding: 0,
          },
          fontSize: `${ThemeFonts.screenxl.tag}px`,
        },
        //@ts-ignore
        //Chip Styles = Default
        colorDefault: {
          "&.MuiChip-filledDefault": {
            borderRadius: "8px" + "!important",
            backgroundColor: Palette.neutral[400],
            color: Palette.text.dark,
            textTransform: "none",
            lineHeight: "140%",
            letterSpacing: "normal",
            fontWeight: 400,
            fontSize: `${ThemeFonts.screenxl.chip}px`,
          },
        },
        colorSuccess: {
          "&.MuiChip-filledSuccess": {
            backgroundColor: Palette.secondary.light3,
            color: Palette.secondary.dark2,
          },
        },
        colorError: {
          "&.MuiChip-filledError": {
            backgroundColor: Palette.error.light3,
            color: Palette.error.dark2,
          },
        },
        colorInfo: {
          "&.MuiChip-filledInfo": {
            backgroundColor: Palette.neutral[300],
            color: Palette.text.grey,
          },
        },
        colorSecondary: {
          "&.MuiChip-filledSecondary": {
            backgroundColor: Palette.success.light3,
            color: Palette.success.dark2,
          },
        },
        colorPrimary: {
          "&.MuiChip-filledPrimary": {
            backgroundColor: Palette.primary.light3,
            color: Palette.primary.main,
          },
        },
        colorWarning: {
          "&.MuiChip-filledWarning": {
            backgroundColor: Palette.neutral[200],
            color: Palette.text.grey,
          },
        },
        sizeMedium: {
          height: 26,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: Palette.neutral[900],
          padding: "12px 16px",
          borderRadius: 8,
          fontWeight: "400",
          fontSize: `${ThemeFonts.screenxl.tooltip}px`,

          span: {
            color: Palette.primary.dark,
          },
          p: {
            fontWeight: "600",
            fontSize: `${ThemeFonts.screenxl.tooltip}px`,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: `${ThemeFonts.screenxl.overline}px`,
          button: {
            position: "relative",
            top: "0.2em",
            padding: 0,
            background: "transparent",
            margin: 0,
            "&:hover": {
              color: Palette.primary.components.light.hover,
              background: "transparent",
            },
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          fontSize: `${ThemeFonts.screenxl.tooltip}px!important`,
        },
        colorSecondary: {
          color: Palette.neutral[50],
          background: Palette.success.dark,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: Palette.text.grey,
          margin: "4px 10px",
          fontSize: 13,
          "&.Mui-focused": {
            color: `${Palette.primary.main}!important`,
          },
          "&.Mui-error": {
            color: `${Palette.error.main}!important`,
          },
          "&.Mui-disabled": {
            color: `${Palette.text.dark}!important`,
            opacity: 0.3,
            textFillColor: `${Palette.text.dark}!important`,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: 44,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 44,
          //Autocomplete textfield
          "&.MuiAutocomplete-inputRoot": {
            padding: "0px 8px 0px 8px!important",
          },
          "&.MuiOutlinedInput-root": {
            color: `${Palette.text.dark}!important`,
          },
        },
        multiline: {
          height: "auto",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "red",
          fontSize: `${ThemeFonts.screenxl.inputText}px`,
        },
        outlined: {
          borderRadius: 8,
          top: "-5px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px!important",
          position: "relative",
          "input, .MuiSelect-select,&.MuiAutocomplete-inputRoot": {
            padding: "10.5px 14px",
            color: Palette.text.dark,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            fontSize: `${ThemeFonts.screenxl.inputText}px`,

            img: {
              borderRadius: "100%",
              height: 20,
              width: 20,
              objectFit: "cover",
              marginRight: 10,
              marginLeft: 5,
            },
          },
          ".MuiInputAdornment-positionEnd, .MuiTypography-root": {
            color: Palette.primary.light2,
          },
          "&.Mui-error": {
            "input, .MuiSelect-select,&.MuiAutocomplete-inputRoot": {
              color: Palette.text.dark,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: Palette.primary.light2,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline , &:hover .MuiOutlinedInput-notchedOutline":
            {
              borderColor: `${Palette.primary.main}!important`,
              borderWidth: 1,
            },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: `${Palette.neutral[600]}!important`,
            opacity: 0.3,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${Palette.error.main}!important`,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: `${Palette.neutral[500]}!important`,
          top: "-6px" + "!important",
          "&.MuiInputLabel-shrink": {
            top: "0px" + "!important",
            "&.Mui-error": {
              color: `${Palette.error.main} !important`,
            },
          },
          "&.Mui-focused": {
            color: `${Palette.primary.main}!important`,
          },
          "&.Mui-disabled": {
            color: `${Palette.text.dark}!important`,
            opacity: 0.3,
            textFillColor: `${Palette.text.dark}!important`,
          },
          "&.Mui-error": {
            color: `${Palette.neutral[600]}!important`,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "+ .MuiAutocomplete-popper": {
            "& .MuiAutocomplete-listbox, & .MuiAutocomplete-noOptions": {
              color: Palette.text.dark,
              img: {
                borderRadius: "100%",
                height: 20,
                width: 20,
                objectFit: "cover",
              },
              ".MuiAutocomplete-option.Mui-focused": {
                backgroundColor: Palette.primary.light3,
              },
              ".MuiAutocomplete-option": {
                color: Palette.text.dark,
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: "none",
        },
        elevation1: {
          boxShadow:
            " 0px 1px 4px rgba(82, 89, 103, 0.08), 0px 0px 0px rgba(82, 89, 103, 0.1), 0px 1px 2px rgba(82, 89, 103, 0.2);",
        },
        elevation2: {
          boxShadow:
            " 0px 15px 35px rgba(82, 89, 103, 0.08), 0px 5px 15px rgba(82, 89, 103, 0.08);", //noPalletcolor
        },
        elevation3: {
          boxShadow:
            "0px 7px 14px rgba(82, 89, 103, 0.08), 0px 3px 6px rgba(82, 89, 103, 0.08);", //noPalletcolor
        },
        elevation4: {
          boxShadow:
            "0px 15px 35px rgba(82, 89, 103, 0.08), 0px 5px 15px rgba(82, 89, 103, 0.08);", //noPalletcolor
        },
        elevation5: {
          boxShadow:
            "0px 15px 35px rgba(82, 89, 103, 0.08), 0px 50px 100px rgba(82, 89, 103, 0.08), 0px 5px 15px rgba(82, 89, 103, 0.08);", //noPalletcolor
        },
        root: {
          "&.MuiMenu-paper": {
            color: Palette.text.dark,
            "& .MuiMenu-list, & .MuiMenuItem-root": {
              color: Palette.text.dark,
              img: {
                borderRadius: "100%",
                height: 20,
                width: 20,
                objectFit: "cover",
                marginRight: 10,
              },
              ".Mui-selected": {
                backgroundColor: Palette.primary.light3,
              },
            },
          },
          "&.MuiMenu-paper, &.MuiAutocomplete-paper": {
            boxShadow:
              "0px 8px 17px rgb(106 167 197 / 15%), 0px 1.6px 2.7625px rgb(106 167 197 / 8%)",
            backgroundColor: `${Palette.background.paper}!important`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTabs-indicator": {
            backgroundColor: Palette.primary.main,
          },
          ".MuiTab-root": {
            fontFamily: "Poppins",
            fontSize: `${ThemeFonts.screenxl.subtitle1}px`,
            fontWeight: 600,
            lineHeight: "120%",
            fontStyle: "normal",
            letterSpacing: "0.0015em",
            padding: "10px 16px 8px",
            borderBottom: `1px solid${Palette.text.lightGrey}`,
            minHeight: 48,

            "&[aria-selected='false']": {
              color: Palette.text.lightGrey,
            },
            "&.Mui-disabled": {
              color: Palette.neutral[600],
              borderBottom: `1px solid${Palette.neutral[600]}`,
              opacity: 0.3,
            },
          },
        },
      },
    },
  },
});

export default theme;
