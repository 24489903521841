import { createNamedStyles } from "../../../shared/utils";

export const HEADER_HEIGHT_SCALE = 12;

export const headerStyles = createNamedStyles({
  content: {
    alignItems: "center",
    color: "text.white",
    display: "flex",
    height: "100%",
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    px: { lg: 12, md: 7, xs: 2 },
    transition: "all 0.5s",
    width: "100%",
  },
  container: {
    bgcolor: "primary.dark2",
    display: "flex",
    flexShrink: 0,
    height: ({ spacing }) => spacing(HEADER_HEIGHT_SCALE),
    justifyContent: "center",
    width: "100%",
  },
  image: {
    display: { md: "block", xs: "none" },
    flexShrink: 0,
    ml: 1,
  },
  title: {
    flexGrow: 1,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
