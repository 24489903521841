import { IDocumentsToPayWebsocketResponseError } from "../../../../store/epics/consultPaymentEpic/consult_payment_epic.interfaces";
import { IQueryServiceResponseErrorANT } from "../../../../store/interfaces/AppState.interfaces";

export const convertToResponseErrorANT = (
  responseWebsocket: IDocumentsToPayWebsocketResponseError
): IQueryServiceResponseErrorANT => {
  const responseANT: IQueryServiceResponseErrorANT = {
    code: responseWebsocket.code,
    message: responseWebsocket.message,
    suggest: responseWebsocket.suggest,
    title: responseWebsocket.title,
  };
  return responseANT;
};
