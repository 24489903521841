import { createNamedStyles } from "../../shared/utils";

export const defaultStyles = createNamedStyles({
  main: {
    backgroundColor: "background.default",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  content: {
    px: { lg: 12, md: 7, xs: 2 },
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    width: "100%",
  },
});
