import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { KushkiIcon } from "../../../assets/icons";
import { headerStyles } from "./Header.styles";

const Header: FC = () => (
  <Box component="header" sx={headerStyles.container}>
    <Box sx={headerStyles.content}>
      <Typography variant="h6" sx={headerStyles.title}>
        Agencia Nacional de Tránsito
      </Typography>

      <KushkiIcon sx={headerStyles.image} />
    </Box>
  </Box>
);

export default Header;
