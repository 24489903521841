import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServicesForm } from "../../../components/Home/PaymentServices/PaymentServices.interfaces";
import {
  AppState,
  CurrentContainerType,
  IAvailabilityResponse,
  ICommissionResponse,
  IQueryServiceResponseANT,
  IQueryServiceResponseErrorANT,
} from "../../interfaces/AppState.interfaces";

const initialState: AppState = {
  currentStep: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setQueryServiceResponseANT: (
      state,
      action: PayloadAction<IQueryServiceResponseANT | undefined>
    ) => {
      state.queryServiceResponseANT = action.payload;
    },
    setQueryServiceResponseErrorANT: (
      state,
      action: PayloadAction<IQueryServiceResponseErrorANT | undefined>
    ) => {
      state.queryServiceResponseErrorANT = action.payload;
    },
    setCommission: (
      state,
      action: PayloadAction<ICommissionResponse | undefined>
    ) => {
      state.commission = action.payload;
    },
    setQueryServiceRequestANT: (
      state,
      action: PayloadAction<ServicesForm | undefined>
    ) => {
      state.queryServiceRequestANT = action.payload;
    },
    setCurrentContainer: (
      state,
      action: PayloadAction<CurrentContainerType>
    ) => {
      state.currentContainer = action.payload;
    },
    setAvailability: (state, action: PayloadAction<IAvailabilityResponse>) => {
      state.availabilityResponse = action.payload;
    },
  },
});

export default appSlice.reducer;
