export const HEAD_LINKS = [
  {
    as: "style",
    href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
    onLoad: "this.rel='stylesheet';",
    rel: "preload",
  },
  {
    href: "/favicons/apple-touch-icon.png",
    rel: "apple-touch-icon",
    sizes: "180x180",
    types: "image/png",
  },
  {
    href: "/favicons/favicon-32x32.png",
    rel: "icon",
    sizes: "32x32",
    types: "image/png",
  },
  {
    href: "/favicons/favicon-16x16.png",
    rel: "icon",
    sizes: "16x16",
    types: "image/png",
  },
  {
    href: "/favicons/favicon.ico",
    rel: "shortcut icon",
  },
  {
    href: "/favicons/site.webmanifest",
    rel: "manifest",
  },
];
