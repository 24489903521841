import { hourTime, timePattern } from "../patterns/patterns";

const defaultDate = "1970-01-01T{{hour}}.000Z";

const convertToLocalTime = (time: string) => {
  const parseTime = time.substring(2, 10);
  return new Date(defaultDate.replace(hourTime, parseTime))
    .toLocaleTimeString("es-EC")
    .substring(0, 5);
};

export const convertMessage = (message: string) => {
  const coincidences = message.match(timePattern);
  coincidences?.forEach((coincidence) => {
    message = message.replace(coincidence, convertToLocalTime(coincidence));
  });

  return message;
};
