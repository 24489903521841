/**
 * Enum Error ANT
 */
import { FC } from "react";
import { SvgIconProps } from "@mui/material";
import {
  ConnectionIcon,
  DateErrorIcon,
  DocumentErrorIcon,
  DocumentSuccessIcon,
  ErrorIcon,
  InformativeIcon,
  NotDebtsIcon,
  NotFoundIcon,
  ServiceUnavailable,
  SuccessIcon,
  UserErrorIcon,
  UserInformationIcon,
  UserSuccessIcon,
} from "../../assets/icons/ErrorIcons";

export enum ErrorCode {
  T8035 = "T8035",
  T8052 = "T8052",
  T9999 = "T9999",
  T0004 = "T0004",
  T0008 = "T0008",
  T0031 = "T0031",
  T0045 = "T0045",
  T8013 = "T8013",
  T8060 = "T8060",
  T0001 = "T0001",
  T0002 = "T0002",
  T0003 = "T0003",
  T0009 = "T0009",
  T0013 = "T0013",
  T0058 = "T0058",
  T0016 = "T0016",
  T0051 = "T0051",
  T8058 = "T8058",
  T8057 = "T8057",
  T8024 = "T8024",
  T0018 = "T0018",
  T8018 = "T8018",
  T8064 = "T8064",
  T8065 = "T8065",
  T8067 = "T8067",
  T0014 = "T0014",
  T0015 = "T0015",
  T0053 = "T0053",
  T0061 = "T0061",
  T0062 = "T0062",
  T0063 = "T0063",
  T0064 = "T0064",
  T8001 = "T8001",
  T8002 = "T8002",
  T8003 = "T8003",
  T8004 = "T8004",
  T8006 = "T8006",
  T8007 = "T8007",
  T8012 = "T8012",
  T8014 = "T8014",
  T8015 = "T8015",
  T8016 = "T8016",
  T8019 = "T8019",
  T8022 = "T8022",
  T8025 = "T8025",
  T8026 = "T8026",
  T8033 = "T8033",
  T8050 = "T8050",
  T8053 = "T8053",
  T8055 = "T8055",
  T8063 = "T8063",
  T8066 = "T8066",
  T8068 = "T8068",
  T8069 = "T8069",
  T8070 = "T8070",
  T8059 = "T8059",
  T8023 = "T8023",
  T8056 = "T8056",
  T8061 = "T8061",
  T8062 = "T8062",
  T0010 = "T0010",
  T0052 = "T0052",
  T0069 = "T0069",
  T8000 = "T8000",
  T0011 = "T0011",
  T0027 = "T0027",
  T0044 = "T0044",
  T0047 = "T0047",
  T0054 = "T0054",
  T0057 = "T0057",
  T0070 = "T0070",
  T0065 = "T0065",
  T0066 = "T0066",
  T0067 = "T0067",
  T0068 = "T0068",
  T8054 = "T8054",
  T8051 = "T8051",
  T0060 = "T0060",
  T8034 = "T8034",
  T0000 = "T0000",
  T0005 = "T0005",
  T0006 = "T0006",
  T0007 = "T0007",
  T0030 = "T0030",
  G9999 = "G9999",
  G9992 = "G9992",
  E400 = "400",
  ANT017 = "ANT017",
  ANT019 = "ANT019",
  ANT021 = "ANT021",
  ANT022 = "ANT022",
  ANT023 = "ANT023",
  ANT004 = "ANT004",
  G0503 = "G0503",
}
export enum ANTTitleEnum {
  ALGO_SALIO_MAL = "Algo salió mal",
  OCURRIO_UN_ERROR = "Ocurrió un error",
  OFF_HOURS = "Recuerda realizar tu trámite hasta las 17:00. Muchas gracias.",
}

export const CustomSuggest = [ErrorCode.T0030];

export const CustomSuggestMessage: Record<string, string> = {
  [ErrorCode.T0030]: ANTTitleEnum.OFF_HOURS,
};

export const ERRORS_ANT: Record<
  ErrorCode,
  {
    icon: FC<SvgIconProps>;
  }
> = {
  [ErrorCode.T0030]: {
    icon: ConnectionIcon,
  },
  [ErrorCode.T8052]: {
    icon: ConnectionIcon,
  },
  [ErrorCode.T9999]: {
    icon: ConnectionIcon,
  },
  [ErrorCode.T0004]: {
    icon: DocumentSuccessIcon,
  },
  [ErrorCode.T0008]: {
    icon: DocumentSuccessIcon,
  },
  [ErrorCode.T0031]: {
    icon: NotDebtsIcon,
  },
  [ErrorCode.T0045]: {
    icon: UserSuccessIcon,
  },
  [ErrorCode.T8013]: {
    icon: NotDebtsIcon,
  },
  [ErrorCode.T8060]: {
    icon: UserSuccessIcon,
  },
  [ErrorCode.T0001]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0002]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0003]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0009]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0013]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0058]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0016]: {
    icon: DateErrorIcon,
  },
  [ErrorCode.T0051]: {
    icon: DateErrorIcon,
  },
  [ErrorCode.T8058]: {
    icon: DateErrorIcon,
  },
  [ErrorCode.T8057]: {
    icon: DateErrorIcon,
  },
  [ErrorCode.T8024]: {
    icon: DateErrorIcon,
  },
  [ErrorCode.T0018]: {
    icon: UserInformationIcon,
  },
  [ErrorCode.T8018]: {
    icon: UserInformationIcon,
  },
  [ErrorCode.T8064]: {
    icon: UserInformationIcon,
  },
  [ErrorCode.T8065]: {
    icon: UserInformationIcon,
  },
  [ErrorCode.T8067]: {
    icon: UserInformationIcon,
  },
  [ErrorCode.T0014]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0015]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0053]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0061]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0062]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0063]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0064]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8001]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8002]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8003]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8004]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8006]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8007]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8012]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8014]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8015]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8016]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8019]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8022]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8025]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8026]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8033]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8050]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8053]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8055]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8063]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8066]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8068]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8069]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8070]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T8059]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T8023]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T8056]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T8061]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T8062]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T0010]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T0052]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T0069]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T8000]: {
    icon: UserErrorIcon,
  },
  [ErrorCode.T0011]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0027]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0044]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0047]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0054]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0057]: {
    icon: InformativeIcon,
  },
  [ErrorCode.T0070]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0065]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0066]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0067]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0068]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T8054]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T8051]: {
    icon: ErrorIcon,
  },
  [ErrorCode.T0060]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T8034]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0000]: {
    icon: SuccessIcon,
  },
  [ErrorCode.T0005]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0006]: {
    icon: DocumentErrorIcon,
  },
  [ErrorCode.T0007]: {
    icon: DocumentSuccessIcon,
  },
  [ErrorCode.T8035]: {
    icon: ConnectionIcon,
  },
  [ErrorCode.G9999]: {
    icon: ErrorIcon,
  },
  [ErrorCode.G9992]: {
    icon: ErrorIcon,
  },
  [ErrorCode.E400]: {
    icon: NotFoundIcon,
  },
  [ErrorCode.ANT017]: {
    icon: ErrorIcon,
  },
  [ErrorCode.ANT019]: {
    icon: ErrorIcon,
  },
  [ErrorCode.ANT021]: {
    icon: ErrorIcon,
  },
  [ErrorCode.ANT022]: {
    icon: ErrorIcon,
  },
  [ErrorCode.ANT023]: {
    icon: ErrorIcon,
  },
  [ErrorCode.ANT004]: {
    icon: ErrorIcon,
  },
  [ErrorCode.G0503]: {
    icon: ServiceUnavailable,
  },
};
