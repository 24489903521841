import get from "lodash/get";
import { IPreChargeWebsocketRequest } from "../../../../../types/pre_charge_request";
import { ICardPaymentForm } from "../../../../components/Checkout/CheckoutForms/Stepper/Payment/state/useCheckoutPaymentFormState.interfaces";
import {
  ServicesForm,
  ServiceType,
} from "../../../../components/Home/PaymentServices/PaymentServices.interfaces";
import {
  IDocumentsToPayBodyRequest,
  IDocumentsToPayWebsocketRequest,
  IServiceType,
} from "../../../../store/epics/consultPaymentEpic/consult_payment_epic.interfaces";
import { ANTServiceTypeEnum, ServiceTypeEnum } from "../../../enums";
import { ITransaction } from "../../../../components/Checkout/store/interfaces/Transaction.interfaces";

const convertServiceType = (currentService: ServiceType): IServiceType => {
  let serviceType: IServiceType = "CIT";
  if (currentService === ServiceTypeEnum.SOL) {
    serviceType = "SOL";
  }
  if (currentService === ServiceTypeEnum.CDP) {
    serviceType = "CDP";
  }
  return serviceType;
};

const convertToBodyRequest = (
  currentService: ServiceType,
  values: {
    idNumber?: string;
    orderNumber?: string;
    vehiclePlate?: string;
  }
): IDocumentsToPayBodyRequest => {
  const serviceType = convertServiceType(currentService);

  let request: Partial<IDocumentsToPayBodyRequest> = {};

  if (values.idNumber) {
    request = {
      documentNumber: values.idNumber,
      documentType: "CED",
      requestType: currentService,
    };
  }
  if (values.vehiclePlate) {
    request = {
      vehiclePlate: values.vehiclePlate,
      requestType: currentService,
    };
  }
  if (values.orderNumber) {
    request = {
      orderNumber: values.orderNumber,
      requestType: currentService,
    };
  }

  request = {
    ...request,
    serviceType,
  };

  return request as IDocumentsToPayBodyRequest;
};

export const convertToWebsocketRequest = (
  dataForm: ServicesForm
): IDocumentsToPayWebsocketRequest => {
  const { serviceType, idNumber, orderNumber, vehiclePlate } = dataForm;

  const websocketRequest: IDocumentsToPayWebsocketRequest = {
    action: "/documents-to-pay",
    request: convertToBodyRequest(serviceType, {
      idNumber,
      orderNumber,
      vehiclePlate,
    }),
  };
  return websocketRequest;
};

// TODO: GET THE DATA FROM documentsToPay FROM THE CORRECT PLACE. ONLY IMPLEMENTED FOR VEHICLE PLATE
export const convertToPreChargeRequest = (
  state: ITransaction,
  payment: ICardPaymentForm,
  token: string
): IPreChargeWebsocketRequest => ({
  action: "/pre-charge",
  request: {
    amount: {
      currency: "USD",
      iva: 0,
      subtotalIva: 0,
      subtotalIva0: Math.round(get(state, "purchase.subAmount", 0) * 100) / 100,
    },
    billingDetails: {
      address: get(state, "billing.address", ""),
      documentNumber: get(state, "billing.documentNumber", ""),
      documentType: get(state, "billing.documentType", ""),
      email: get(state, "billing.email", ""),
      phoneNumber: get(state, "billing.phone", ""),
      name: get(state, "billing.name", ""),
    },
    documentsToPay: {
      serviceType:
        ANTServiceTypeEnum[
          get(state, "applicant.serviceType") as keyof typeof ANTServiceTypeEnum
        ],
      documentNumber: get(state, "applicant.documentNumber"),
      documentType: get(state, "applicant.documentType"),
      orderNumber: get(state, "applicant.orderNumber"),
      requestType: get(state, "applicant.serviceType"),
      vehiclePlate: get(state, "applicant.vehiclePlateNumber"),
    },
    email: get(payment, "email", ""),
    isDeferred: get(payment, "isDeferred", false),
    name: get(payment, "name", ""),
    clientName: get(state, "applicant.name", ""),
    token,
    deferredInfo: {
      months: Number(get(payment, "deferredData.months")),
      monthsGrace: get(payment, "deferredData.monthsOfGrace", "0"),
      typeDeferred: get(payment, "deferredData.typeDeferred"),
    },
  },
});
