/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/indent */
import axios from "axios-observable";
import { createAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import {
  catchError,
  EMPTY,
  filter,
  ignoreElements,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { setIsCheckoutLoading, setTransaction } from "../../actions/actions";
import {
  IApplicantAndPurchase,
  ITransaction,
} from "../../interfaces/Transaction.interfaces";
import {
  ICommissionRequestBody,
  ICommissionResponse,
} from "../../../../../shared/interfaces/Commission.interfaces";
import { EpicCustom } from "../../../../../store/store.interfaces";
import {
  PUBLIC_COMMISSION_URL,
  PUBLIC_MERCHANT_ID,
} from "../../../../../shared/constants";

export const setTransactionData = createAction<IApplicantAndPurchase>(
  "SET_REQUIRED_TRANSACTION_DATA"
);

export const setTransactionDataEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(setTransactionData.match),
    switchMap(({ payload }) => {
      const kushkiCommission = payload.purchase?.kushkiCommission;
      const commissionIva = payload.purchase?.iva;

      if (!kushkiCommission || !commissionIva) {
        const commissionBody: ICommissionRequestBody = {
          currency: "USD",
          totalAmount: payload.purchase?.amount!,
        };
        return axios
          .post<ICommissionResponse>(
            PUBLIC_COMMISSION_URL ?? "",
            commissionBody,
            {
              headers: {
                "Public-Merchant-Id": PUBLIC_MERCHANT_ID ?? "",
              },
            }
          )
          .pipe(
            map(
              ({
                data: {
                  amount: { subtotalIva, iva },
                },
              }) =>
                ({
                  ...payload,
                  purchase: {
                    ...payload.purchase,
                    kushkiCommission: subtotalIva,
                    iva,
                  },
                } as IApplicantAndPurchase)
            )
          );
      }

      return of(payload);
    }),
    tap(({ applicant, purchase }) => {
      const iva = purchase!.iva!;
      const subAmount = purchase!.amount;
      const totalAmount = purchase!.amount + iva + purchase!.kushkiCommission!;

      const transaction: ITransaction = {
        applicant,
        billing: {
          address: "",
          completed: false,
          documentNumber: applicant?.documentNumber!,
          documentType: applicant?.documentType,
          email: "",
          name: applicant?.name!,
          phone: "",
        },
        paying: {
          name: "",
          email: "",
        },
        payment: {
          name: "",
          cardNumber: "",
          cvc: "",
          expDate: "",
          terms: false,
          email: "",
          isDeferred: false,
          deferredData: {
            months: "",
            monthsOfGrace: "",
            typeDeferred: "",
          },
        },
        purchase: {
          ...purchase!,
          iva,
          subAmount,
          totalAmount,
        },
      };

      batch(() => {
        dispatch(setTransaction(transaction));
        dispatch(setIsCheckoutLoading(false));
      });
    }),
    catchError(() => EMPTY),
    ignoreElements()
  );
