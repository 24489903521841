/* eslint-disable consistent-return */
import { CashTokenRequest } from "@kushki/js/lib/types/cash_token_request";
import { ValidateSessionResponse } from "@kushki/js/lib/types/validate_session_response";
import { FC, PropsWithChildren, createContext, useContext } from "react";
import { Kushki } from "@kushki/js";
import { TokenRequest } from "@kushki/js/lib/types/token_request";
import { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import { BankListResponse } from "@kushki/js/lib/types/bank_list_response";
import { TransferTokenRequest } from "@kushki/js/lib/types/remote/transfer_token_request";
import { TransferSubscriptionTokenRequest } from "@kushki/js/lib/types/transfer_subscription_token_request";
import { BinInfoResponse } from "@kushki/js/lib/types/bin_info_response";
import { BinBody } from "@kushki/js/lib/types/bin_body";
import { IDeferredResponse } from "@kushki/js/lib/types/remote/deferred_response";
import { ErrorResponse } from "@kushki/js/lib/types/error_response";
import { SubscriptionTokenRequest } from "@kushki/js/lib/types/subscription_token_request";
import { SecureOtpRequest } from "@kushki/js/lib/types/secure_otp_request";
import { SecureOtpResponse } from "@kushki/js/lib/types/secure_otp_response";
import get from "lodash/get";
import { CardAsyncTokenRequest } from "@kushki/js/lib/types/card_async_token_request";
import { CardAsyncTokenResponse } from "@kushki/js/lib/types/card_async_token_response";
import { SubscriptionCardAsyncTokenRequest } from "@kushki/js/lib/types/subscription_card_async_token_request";
import { SubscriptionCardAsyncTokenResponse } from "@kushki/js/lib/types/subscription_card_async_token_response";
import { SavedPaymentMethodRequest } from "@kushki/js/lib/types/saved_payment_data_request";
import { PaymentDataResponse } from "@kushki/js/lib/types/payment_data_response";
import { VerifyAuthResponse } from "@kushki/js/lib/types/verify_auth_response";
import { DeletePaymentMethodRequest } from "@kushki/js/lib/types/delete_payment_method_request";
import { InitAuthRequest } from "@kushki/js/lib/types/init_auth_request";
import { InitAuthResponse } from "@kushki/js/lib/types/init_auth_response";
import { TokenKPayRequest } from "@kushki/js/lib/types/token_kpay_request";
import { SubscriptionTokenKPayRequest } from "@kushki/js/lib/types/subscription_token_kpay_request";
import { Validate3DsResponse } from "@kushki/js/lib/types/validate_3ds_response";

export interface IKushkiJsContextProps {
  merchantId: string;
  inTestEnvironment: boolean;
}
export type TKushkiJsContextProps = IKushkiJsContextProps;

export interface IKushkiJsContext {
  deletePaymentMethod: (
    request: DeletePaymentMethodRequest
  ) => Promise<boolean>;
  requestCardToken: (
    request: TokenRequest | TokenKPayRequest
  ) => Promise<TokenResponse>;
  requestTransferToken: (
    request: TransferTokenRequest
  ) => Promise<TokenResponse>;
  requestBankList: () => Promise<BankListResponse>;
  requestTransferSubscriptionBankList: () => Promise<BankListResponse>;
  requestDeferred: (bin: BinBody) => Promise<IDeferredResponse[]>;
  requestInitAuth: (request: InitAuthRequest) => Promise<InitAuthResponse>;
  requestCardAsyncToken: (
    request: CardAsyncTokenRequest
  ) => Promise<CardAsyncTokenResponse>;
  requestCardSubscriptionToken: (
    request: SubscriptionTokenRequest | SubscriptionTokenKPayRequest
  ) => Promise<TokenResponse>;
  requestBin: (bin: BinBody) => Promise<BinInfoResponse>;
  requestBrands: () => Promise<string[]>;
  requestCashToken: (request: CashTokenRequest) => Promise<TokenResponse>;
  requestKpayOtpValidation: (code: string) => Promise<VerifyAuthResponse>;
  requestTransferSubscriptionToken: (
    request: TransferSubscriptionTokenRequest
  ) => Promise<TokenResponse>;
  requestSecureServiceValidation: (
    request: SecureOtpRequest
  ) => Promise<SecureOtpResponse>;
  requestValidateKPaySession: () => Promise<ValidateSessionResponse>;
  request3DS: (tokenResponse: TokenResponse) => Promise<Validate3DsResponse>;
  requestSavedPaymentMethods: (
    request: SavedPaymentMethodRequest
  ) => Promise<PaymentDataResponse[]>;
  requestSubscriptionCardAsyncToken: (
    request: SubscriptionCardAsyncTokenRequest
  ) => Promise<SubscriptionCardAsyncTokenResponse>;
}

export type TKushkiJsContext = IKushkiJsContext;

const KushkiJsContext = createContext<TKushkiJsContext>(undefined!);

export const KushkiJsProvider: FC<TKushkiJsContextProps> = ({
  children,
  merchantId,
  inTestEnvironment,
}: PropsWithChildren<TKushkiJsContextProps>) => {
  const kushkiJs: Kushki = new Kushki({ inTestEnvironment, merchantId });

  const request3DS = (request: TokenResponse): Promise<Validate3DsResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.validate3DS(
        request,
        (response: Validate3DsResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestCardToken = (
    request: TokenRequest | TokenKPayRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestDeferred = (bin: BinBody): Promise<IDeferredResponse[]> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestDeferred(
        bin,
        (response: IDeferredResponse[] | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestCashToken = (
    request: CashTokenRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestCashToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestTransferToken = (
    request: TransferTokenRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestTransferToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

  const requestBankList = (): Promise<BankListResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestBankList((response: BankListResponse | ErrorResponse) => {
        if ("code" in response || "message" in response) {
          return reject(response);
        }
        resolve(response);
      });
    });

  const requestTransferSubscriptionBankList = (): Promise<BankListResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestTransferSubscriptionBankList(
        (response: BankListResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

  const requestTransferSubscriptionToken = (
    request: TransferSubscriptionTokenRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestTransferSubscriptionToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

  const requestCardSubscriptionToken = (
    request: SubscriptionTokenRequest | SubscriptionTokenKPayRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestSubscriptionToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestCardAsyncToken = (
    request: CardAsyncTokenRequest
  ): Promise<CardAsyncTokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestCardAsyncToken(
        request,
        (response: CardAsyncTokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestBin = (bin: BinBody): Promise<BinInfoResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestBinInfo(
        bin,
        (response: BinInfoResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestBrands = (): Promise<string[]> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestBrandsByMerchant((response: string[] | ErrorResponse) => {
        if ("code" in response || "message" in response) {
          return reject(response);
        }

        resolve(response);
      });
    });

  const requestSecureServiceValidation = (
    request: SecureOtpRequest
  ): Promise<SecureOtpResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestSecureServiceValidation(
        request,
        (response: SecureOtpResponse | ErrorResponse) => {
          const code: string = get(response, "code");
          const message: string = get(response, "message");
          const validOtp: boolean = message === "ok" || code === "OTP000";
          const valid3DS: boolean =
            (message === "3DS000" && code === "ok") ||
            (code === "3DS000" && message === "ok");

          if (validOtp || valid3DS) return resolve(response);
          reject(response);
        }
      );
    });

  const requestSavedPaymentMethods = (
    request: SavedPaymentMethodRequest
  ): Promise<PaymentDataResponse[]> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestSavedPaymentMethods(
        request,
        (response: PaymentDataResponse[] | ErrorResponse) => {
          if (
            "code" in response ||
            "message" in response ||
            !Array.isArray(response)
          ) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestValidateKPaySession = (): Promise<ValidateSessionResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.validateSession(
        (response: ValidateSessionResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

  const requestSubscriptionCardAsyncToken = (
    request: SubscriptionCardAsyncTokenRequest
  ): Promise<TokenResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestSubscriptionCardAsyncToken(
        request,
        (response: SubscriptionCardAsyncTokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }

          resolve(response);
        }
      );
    });

  const requestKpayOtpValidation = (
    code: string
  ): Promise<VerifyAuthResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestVerifyAuth(
        { code },
        (response: VerifyAuthResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

  const deletePaymentMethod = (
    request: DeletePaymentMethodRequest
  ): Promise<boolean> =>
    new Promise((resolve, reject) => {
      kushkiJs.deletePaymentMethod(
        { ...request },
        (response: boolean | ErrorResponse) => {
          if (typeof response === "boolean") {
            resolve(!!response);
          }

          if (get(response, "message") !== "ok") {
            return reject(response);
          }
          resolve(true);
        }
      );
    });

  const requestInitAuth = (
    request: InitAuthRequest
  ): Promise<InitAuthResponse> =>
    new Promise((resolve, reject) => {
      kushkiJs.requestInitAuth(
        request,
        (response: InitAuthResponse | ErrorResponse) => {
          if (!("result" in response)) return reject(response);
          resolve(response);
        }
      );
    });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: TKushkiJsContext = {
    deletePaymentMethod,
    requestBankList,
    requestBin,
    requestBrands,
    requestCardAsyncToken,
    requestCardSubscriptionToken,
    requestCardToken,
    requestCashToken,
    requestDeferred,
    requestInitAuth,
    requestKpayOtpValidation,
    requestSavedPaymentMethods,
    requestSecureServiceValidation,
    requestSubscriptionCardAsyncToken,
    requestTransferSubscriptionBankList,
    requestTransferSubscriptionToken,
    requestTransferToken,
    requestValidateKPaySession,
    request3DS,
  };

  return (
    <KushkiJsContext.Provider value={value}>
      {children}
    </KushkiJsContext.Provider>
  );
};

export const useKushkiJsContext = () => useContext(KushkiJsContext);
