import { checkoutSlice } from "../reducer/reducer";

export const {
  cleanTransaction,
  setApplicant,
  setApplicantAndPurchase,
  setBilling,
  setPaying,
  setIsCheckoutLoading,
  setPurchase,
  setTransaction,
  setPaymentErrorMessage,
  setPaymentErrorTitle,
  setPaymentErrorSubTitle,
  setPaymentErrorSuggest,
  setPaymentSuccess,
  setIsPaymentLoading,
  setErrorCode,
  setInitState,
  setPayment,
} = checkoutSlice.actions;
