/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import { createAction } from "@reduxjs/toolkit";
import {
  catchError,
  EMPTY,
  ignoreElements,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import { filter } from "rxjs/operators";
import { Kushki } from "@kushki/js";
import { TokenRequest } from "@kushki/js/lib/types/token_request";
import { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import { batch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  setErrorCode,
  setInitState,
  setIsPaymentLoading,
  setPaymentErrorSubTitle,
  setPaymentErrorSuggest,
  setPaymentErrorTitle,
  setPaymentSuccess,
} from "../../actions/actions";
import { createWebsocket } from "../../../../../shared/utils";
import {
  AntPayDocumentResponse,
  IExtraDataToSend,
  IKushkiObservableResponse,
} from "./subscribe_transaction_epic.interfaces";
import { setCurrentStep } from "../../../../../store/actions/actions";
import { AppDispatch, EpicCustom } from "../../../../../store/store.interfaces";
import { ICardPaymentForm } from "../../../CheckoutForms/Stepper/Payment/state/useCheckoutPaymentFormState.interfaces";
import { convertToPreChargeRequest } from "../../../../../shared/utils/queryPayment/convertToWebsocketRequest/convert_to_websocket_request";
import { WS_ANT_DOCUMENTS_URL } from "../../../../../shared/constants";
import { IPreChargeWebsocketRequest } from "../../../../../../types/pre_charge_request";
import { ErrorCode } from "../../../../../shared/enums/errorEnum";
import { IAvailabilityResponse } from "../../../../../store/interfaces/AppState.interfaces";
import axios from "../../../../../shared/utils/axios";
import { API_ROUTES } from "../../../../../shared/constants/api_routes";
import { convertMessage } from "../../../../../shared/utils/availabilityMessage/availabilityMessage";

let title: string;
let message: string;
let suggest: string;

export enum MessageEnum {
  SUCCESS_TITLE = "Trámite Exitoso",
  SUCCESS_WITH_TIMEOUT_TITLE = "Algo salió mal",
  SUCCESS_MESSAGE = "Hemos enviado el comprobante de pago a tu correo electrónico",
  SUCCESS_WITH_TIMEOUT_SUBTITLE = "Transacción declinada",
  SUCCESS_WITH_TIMEOUT_SUGGESTION = "Por favor inténtalo más tarde.",
  GENERIC_ERROR_TITLE = "Algo salió mal",
  GENERIC_ERROR_MESSAGE = "Presentamos problemas para realizar tu consulta",
  GENERIC_ERROR_SUGGEST = "Por favor inténtalo más tarde.",
}

const kushkiObservable = (
  data: ICardPaymentForm,
  extraData: IExtraDataToSend
) => {
  const dataToSend: TokenRequest = {
    amount: Number.parseFloat(extraData.amount),
    savePaymentData: extraData.savePaymentData,
    isDeferred: data.isDeferred,
    currency: "USD",
    card: {
      cvc: data.cvc,
      name: data.name,
      expiryMonth: data.expDate.split("/")[0],
      expiryYear: data.expDate.split("/")[1],
      number: data.cardNumber.replace(/\s/g, ""),
    },
  };

  const observable = new Observable<IKushkiObservableResponse>((subscriber) => {
    const kushki = new Kushki({
      merchantId: process.env.NEXT_PUBLIC_MERCHANT_ID ?? "",
      inTestEnvironment: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
    });
    //TODO: Add error management before review
    kushki.requestToken(dataToSend, (response) => {
      subscriber.next({
        kushkiToken: (response as TokenResponse).token,
        data,
      });
    });
  });
  return observable;
};

const createWebsocketObservable = (requestAnt: IPreChargeWebsocketRequest) => {
  const observable = new Observable<AntPayDocumentResponse>((subscriber) => {
    const websocket = createWebsocket(WS_ANT_DOCUMENTS_URL ?? "");
    websocket.addEventListener("open", () => {
      websocket.send(JSON.stringify(requestAnt));
    });
    websocket.addEventListener("error", (error) => {
      subscriber.error({ title: "Error from Websocket", message: error });
    });
    websocket.addEventListener("message", (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data.status !== "PROCESSING") {
        websocket.close();
        subscriber.next(data);
      }
    });
    websocket.addEventListener("close", () => {
      subscriber.complete();
    });
  });
  return observable;
};

export const subscribeToTransaction = createAction<{
  dataForm: ICardPaymentForm;
}>("SUBSCRIBE_TO_TRANSACTION");

function isUnknownError(wsErrorCode?: ErrorCode) {
  return !Object.values(ErrorCode).includes(<ErrorCode>wsErrorCode);
}

function handleSuccessResponse(dispatch: AppDispatch, isTimeout: boolean) {
  if (isTimeout) {
    batch(() => {
      dispatch(setInitState(false));
      dispatch(setCurrentStep(0));
      dispatch(setIsPaymentLoading(false));
      dispatch(setPaymentErrorTitle(MessageEnum.SUCCESS_WITH_TIMEOUT_TITLE));
      dispatch(
        setPaymentErrorSubTitle(MessageEnum.SUCCESS_WITH_TIMEOUT_SUBTITLE)
      );
      dispatch(
        setPaymentErrorSuggest(MessageEnum.SUCCESS_WITH_TIMEOUT_SUGGESTION)
      );
      dispatch(setErrorCode(ErrorCode.ANT004));
    });
  }

  // TODO isTimeout branch flow already handled.
  title = isTimeout
    ? MessageEnum.SUCCESS_WITH_TIMEOUT_TITLE
    : MessageEnum.SUCCESS_TITLE;
  message = isTimeout
    ? MessageEnum.SUCCESS_WITH_TIMEOUT_SUBTITLE
    : MessageEnum.SUCCESS_MESSAGE;
  batch(() => {
    dispatch(setCurrentStep(3));
    dispatch(setIsPaymentLoading(false));
    dispatch(setPaymentSuccess(true));
    dispatch(setPaymentErrorTitle(title));
    dispatch(setPaymentErrorSubTitle(message));
    if (isTimeout) {
      dispatch(
        setPaymentErrorSuggest(MessageEnum.SUCCESS_WITH_TIMEOUT_SUGGESTION)
      );
    }
    dispatch(setErrorCode(isTimeout ? ErrorCode.ANT004 : ErrorCode.T0000));
  });
}

function handleErrorResponse(
  dispatch: AppDispatch,
  preChargeResponse?: AntPayDocumentResponse,
  wsErrorCode?: ErrorCode
) {
  title = get(
    preChargeResponse,
    "payload.title",
    MessageEnum.GENERIC_ERROR_TITLE
  );
  message = get(
    preChargeResponse,
    "payload.message",
    MessageEnum.GENERIC_ERROR_MESSAGE
  );
  suggest = get(preChargeResponse, "payload.suggest");

  wsErrorCode = !isUnknownError(wsErrorCode) ? wsErrorCode : ErrorCode.G9999;
  batch(() => {
    dispatch(setInitState(false));
    dispatch(setIsPaymentLoading(false));
    dispatch(setPaymentSuccess(false));
    dispatch(setErrorCode(<ErrorCode>wsErrorCode));
    dispatch(setPaymentErrorTitle(title));
    dispatch(setPaymentErrorSubTitle(message));
    dispatch(setPaymentErrorSuggest(suggest));
    dispatch(setCurrentStep(0));
  });
}

export const subscribeToTransactionEpic: EpicCustom = ({
  action$,
  dispatch,
  state$,
}) =>
  action$.pipe(
    filter(subscribeToTransaction.match),
    switchMap((data) =>
      of(data).pipe(
        tap(() => {
          dispatch(setIsPaymentLoading(true));
        }),
        mergeMap(() =>
          axios.get<IAvailabilityResponse>(API_ROUTES.availability)
        ),
        mergeMap((response) => {
          if (response.data.availability) return of(undefined);

          handleErrorResponse(
            dispatch,
            {
              payload: {
                title: "Lo sentimos",
                message: `El pago no pudo ser procesado. ${convertMessage(
                  response.data.message
                )}`,
              },
            },
            ErrorCode.G0503
          );

          return EMPTY;
        }),
        switchMap(() => {
          const dataAmount =
            state$!.value.checkout.transaction?.purchase?.subAmount;

          return kushkiObservable(data.payload.dataForm, {
            amount: dataAmount,
          });
        }),
        switchMap((kushkiResponse: IKushkiObservableResponse) => {
          if (!kushkiResponse.kushkiToken) {
            throw new Error("Invalid Kushki Token");
          }

          const dataToSend = convertToPreChargeRequest(
            state$!.value.checkout.transaction,
            kushkiResponse.data,
            kushkiResponse.kushkiToken
          );

          return createWebsocketObservable(dataToSend);
        }),
        tap((preChargeResponse: AntPayDocumentResponse) => {
          batch(() => {
            const wsErrorCode = get(preChargeResponse, "payload.code");
            const isTimeout = wsErrorCode === ErrorCode.ANT004;
            const isSuccessful = isEmpty(wsErrorCode) || isTimeout;

            if (isSuccessful) {
              handleSuccessResponse(dispatch, isTimeout);
            } else {
              handleErrorResponse(
                dispatch,
                preChargeResponse,
                <ErrorCode>wsErrorCode
              );
            }
          });
        }),
        catchError(() => {
          batch(() => {
            handleErrorResponse(
              dispatch,
              {
                payload: {
                  title: "Algo salió mal",
                  message: "Transacción declinada",
                  suggest: MessageEnum.GENERIC_ERROR_SUGGEST,
                },
              },
              ErrorCode.ANT019
            );
          });
          return EMPTY;
        })
      )
    ),
    ignoreElements()
  );
