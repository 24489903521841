import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { KushkiIcon } from "../../../assets/icons";
import { footerStyles } from "./Footer.styles";

const Footer: FC = () => (
  <Box component="footer" sx={footerStyles.container}>
    <Box sx={footerStyles.content}>
      <Typography variant="h6" sx={footerStyles.title}>
        Agencia Nacional de Tránsito
      </Typography>

      <KushkiIcon sx={footerStyles.kushkiIcon} data-testid="kushki" />
    </Box>
  </Box>
);

export default Footer;
