export const getNumericPattern = () => /^[0-9]+$/i;

export const getAlphabeticPattern = () => /^[a-zñáéíóúü]+$/i;

export const getAlphanumericPattern = () =>
  /^(?=.*[0-9])(?=.*[a-zñáéíóúü])([0-9a-zñáéíóúü]+)$/i;

export const getPlatePattern = () => /^[a-zA-Z]{3}[0-9]{3,4}$/i;

export const getTextPattern = () => /^(?=.*[a-zñáéíóúü])[a-zñáéíóúü\s]+$/i;

export const getPhonePattern = () =>
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i;

export const getEmailPattern = () => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i;

export const timePattern = /{{([\d]{2}:[\d]{2}:[\d]{2})}}/g;

export const hourTime = /{{hour}}/i;
