import { appSlice } from "../reducers/app/app";

export const {
  setCurrentStep,
  setQueryServiceResponseANT,
  setQueryServiceResponseErrorANT,
  setQueryServiceRequestANT,
  setCommission,
  setCurrentContainer,
  setAvailability,
} = appSlice.actions;
