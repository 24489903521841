import { createNamedStyles } from "../../../shared/utils";

export const FOOTER_HEIGHT_SCALE = 13;

export const footerStyles = createNamedStyles({
  container: {
    bgcolor: "background.paper",
    boxShadow: "-24px 24px 72px -8px rgba(145, 158, 171, 0.24)",
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    minHeight: ({ spacing }) => spacing(FOOTER_HEIGHT_SCALE),
    width: "100%",
  },
  content: {
    alignItems: "center",
    color: "text.blue",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    height: "100%",
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    px: { lg: 16, md: 10, xs: 2 },
    py: { xs: 4, md: 0 },
    transition: "all 0.5s",
    width: "100%",
  },
  kushkiIcon: {
    ml: { md: 4, xs: 0 },
  },
  title: {
    mb: { md: 0, xs: 2 },
    flexGrow: { md: 1, xs: 0 },
    fontWeight: 500,
  },
});
