/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICheckoutState } from "../interfaces/Checkout.interfaces";
import {
  IApplicantAndPurchase,
  IApplicantInformation,
  IBillingInformation,
  IPayingInformation,
  IPaymentInformation,
  IPurchaseInformation,
  ITransaction,
} from "../interfaces/Transaction.interfaces";
import { ErrorCode } from "../../../../shared/enums/errorEnum";

const EMPTY_TRANSACTION = {
  billing: {
    address: "",
    completed: false,
    documentNumber: "",
    documentType: "",
    email: "",
    name: "",
    phone: "",
  },
  paying: {
    email: "",
    name: "",
  },
  // if react-redux throw errors because there is a nested object, you can extract deferredData as another property of the state
  payment: {
    name: "",
    cardNumber: "",
    cvc: "",
    expDate: "",
    terms: false,
    email: "",
    isDeferred: false,
    deferredData: {
      months: "",
      monthsOfGrace: "",
      typeDeferred: "",
    },
  },
};

export const initialState: ICheckoutState = {
  transaction: EMPTY_TRANSACTION,
  isCheckoutLoading: true,
  isPaymentLoading: false,
  errorCode: ErrorCode.T0000,
  initState: true,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    cleanTransaction: (state: ICheckoutState) => {
      state.transaction.billing = EMPTY_TRANSACTION.billing;
      state.transaction.payment = EMPTY_TRANSACTION.payment;
      state.isCheckoutLoading = true;
      state.initState = true;
    },
    setApplicant: (state, action: PayloadAction<IApplicantInformation>) => {
      state.transaction.applicant = action.payload;
    },
    setApplicantAndPurchase: (
      state,
      action: PayloadAction<IApplicantAndPurchase>
    ) => {
      state.transaction.applicant = action.payload.applicant;
      state.transaction.purchase = action.payload.purchase;
    },
    setBilling: (state, action: PayloadAction<IBillingInformation>) => {
      state.transaction.billing = action.payload;
    },
    setPaying: (state, action: PayloadAction<IPayingInformation>) => {
      state.transaction.paying = action.payload;
    },
    setPayment: (state, action: PayloadAction<IPaymentInformation>) => {
      state.transaction.payment = action.payload;
    },
    setPurchase: (state, action: PayloadAction<IPurchaseInformation>) => {
      state.transaction.purchase = action.payload;
    },
    setIsCheckoutLoading: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutLoading = action.payload;
    },
    setTransaction: (state, action: PayloadAction<ITransaction>) => {
      state.transaction = action.payload;
    },
    setPaymentSuccess: (state, action: PayloadAction<boolean>) => {
      state.isPaymentSuccess = action.payload;
    },
    setPaymentErrorMessage: (state, action: PayloadAction<string>) => {
      state.paymentErrorMessage = action.payload;
    },
    setPaymentErrorTitle: (state, action: PayloadAction<string>) => {
      state.paymentErrorTitle = action.payload;
    },
    setPaymentErrorSubTitle: (state, action: PayloadAction<string>) => {
      state.paymentErrorSubtitle = action.payload;
    },
    setPaymentErrorSuggest: (state, action: PayloadAction<string>) => {
      state.paymentErrorSuggest = action.payload;
    },
    setIsPaymentLoading: (state, action: PayloadAction<boolean>) => {
      state.isPaymentLoading = action.payload;
    },
    setErrorCode: (state, action: PayloadAction<ErrorCode>) => {
      state.errorCode = action.payload;
    },
    setInitState: (state, action: PayloadAction<boolean>) => {
      state.initState = action.payload;
    },
  },
});

export default checkoutSlice.reducer;
