import { FC } from "react";
import { Box } from "@mui/material";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { defaultStyles } from "./Default.styles";

const DefaultLayout: FC = ({ children }) => (
  <>
    <Header />
    <Box component="main" sx={defaultStyles.main}>
      <Box sx={defaultStyles.content}>{children}</Box>
    </Box>
    <Footer />
  </>
);

export default DefaultLayout;
